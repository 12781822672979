import { req } from '../js/config';
import * as base from '../js/base';

const courseFee = 650;
const CDIelts = 317.7;
const PBIelts = 317.7;
const EORFee = 250;
const ExpressShipping = 53.1;
const AdditionalTRF = 25;
const AdditionalTRFPlusExpressShipping = 80;
const IeltsTransfer = 90;
const UniversityPreprationProgramApplication = 100;
const EnglishLiteracyDevelopmentProgramApplication = 100;
const dateForOrder = new Date();
const orderNumber =
  'XYNA' +
  dateForOrder
    .getFullYear()
    .toString()
    .concat((dateForOrder.getMonth() + 1).toString())
    .concat(dateForOrder.getDate().toString())
    .concat(dateForOrder.getHours().toString())
    .concat(dateForOrder.getMinutes().toString())
    .concat(dateForOrder.getSeconds().toString());
console.log('🚀 ~ file: main.js ~ line 13 ~ orderNumber', orderNumber);

let TotalFee = 0;
let SubTotal = 0;
let TaxAmount = 0;
let FirstEventIncrement = 0;
let ThreeDFormSubmitted = false;

// Tax rates
const hstRate = 0.13;
const gstRate = 0.05;

// Tax map based on location
const locationTaxMap = {
  Brampton: 'HST',
  'Brampton East': 'HST',
  Caledon: 'HST',
  Vaughan: 'HST',
  'Richmond Hill': 'HST',
  Oakville: 'HST',
  Guelph: 'HST',
  Hamilton: 'HST',
  Kitchener: 'HST',
  Waterloo: 'HST',
  Markham: 'HST',
  Barrie: 'HST',
  'Niagara Falls': 'HST',
  Windsor: 'HST',
  Edmonton: 'GST',
  Calgary: 'GST',
};

const Initialize = async () => {
  console.log('Initialize');
  HidePageControls();
};

const HidePageControls = async () => {
  document.querySelector('#CourseFeeLocation').style.display = 'none';
  document.querySelector('#PBIeltsFeeLocation').style.display = 'none';
  document.querySelector('#CDIeltsFeeLocation').style.display = 'none';
  document.querySelector('#IeltsEORFeeLocation').style.display = 'none';
  document.querySelector('#IeltsExpressShippingFeeLocation').style.display =
    'none';
  document.querySelector('#AdditionalTRFRequestFeeLocation').style.display =
    'none';
  document.querySelector(
    '#AdditionalTRFRequestPlusExpressShippingFeeLocation'
  ).style.display = 'none';
  document.querySelector('#IeltsTransferFeeLocation').style.display = 'none';
  document.querySelector('#CustomFeeLocation').style.display = 'none';
  document.querySelector('#UPPApplicationFeeLocation').style.display = 'none';
  document.querySelector('#ELDPApplicationFeeLocation').style.display = 'none';
  document.querySelector('#td_SubTotal').innerHTML = '$0.00';
  document.querySelector('#td_Total').innerHTML = '$0.00';
  document.querySelector('#td_Tax').innerHTML = '$0.00';
  document.querySelector('#tr_CustomFee').style.display = 'none';
  document.querySelector('#testDate').style.display = 'none';
  document.querySelector('#successMessageRow').style.display = 'none';
  document.querySelector('#displayBox').style.display = 'none';

  // Hide the promo code and discount when fee type changes
  document.querySelector('#promoCodeRow').style.display = 'none';
  document.querySelector('#discountRow').style.display = 'none';
  document.querySelector('#td_Discount').innerHTML = '$0.00'; // Reset discount
  document.querySelector('#td_PromoCode').innerHTML = ''; // Reset promo code
  TotalFee = 0;
  SubTotal = 0;
  TaxAmount = 0;
};

const ClearPageControls = async () => {
  ClearLocationFields();
  document.querySelector('#UPPApplicationFeeLocation').selectedIndex = 0;
  document.querySelector('#ELDPApplicationFeeLocation').selectedIndex = 0;
  document.querySelector('#td_SubTotal').innerHTML = '$0.00';
  document.querySelector('#td_Total').innerHTML = '$0.00';
  document.querySelector('#td_Tax').innerHTML = '$0.00';

  document.querySelector('#firstNameField').value = '';
  document.querySelector('#lastNameField').value = '';
  document.querySelector('#phoneField').value = '';
  document.querySelector('#emailField').value = '';
  document.querySelector('#noteField').value = '';
  document.querySelector('#testDateField').value = '';
  document.querySelector('#cardholderNameField').value = '';
  document.querySelector('#expiryDateField').value = '';
  document.querySelector('#cardNumberField').value = '';
  document.querySelector('#securityCodeField').value = '';

  document.querySelector('#FeeType').selectedIndex = 0;
  document.querySelector('#td_FeeType').innerHTML = '...';
  document.querySelector('#td_Fee').innerHTML = '...';
  document.querySelector('#customFeeField').value = 0;

  clearPreviousFee();
  
  HidePageControls();
};

const ClearLocationFields = async () => {
  document.querySelector('#CourseFeeLocation').selectedIndex = 0;
  document.querySelector('#PBIeltsFeeLocation').selectedIndex = 0;
  document.querySelector('#CDIeltsFeeLocation').selectedIndex = 0;
  document.querySelector('#IeltsEORFeeLocation').selectedIndex = 0;
  document.querySelector('#IeltsExpressShippingFeeLocation').selectedIndex = 0;
  document.querySelector('#AdditionalTRFRequestFeeLocation').selectedIndex = 0;
  document.querySelector(
    '#AdditionalTRFRequestPlusExpressShippingFeeLocation'
  ).selectedIndex = 0;
  document.querySelector('#IeltsTransferFeeLocation').selectedIndex = 0;
  document.querySelector('#CustomFeeLocation').selectedIndex = 0;
  document.querySelector('#UPPApplicationFeeLocation').selectedIndex = 0;
  document.querySelector('#ELDPApplicationFeeLocation').selectedIndex = 0;
}
const discountAmount = 50; // Discount amount
//const validPromoCode = 'XYNAED50'; // Promo code to display
const promoCodeMap = {
  Calgary: "XYNACAL50",
  Edmonton: "XYNAED50"
};

const CalculateTotal = async (fee, isTaxable, location,feeType) => {
  let taxAmount = 0;
  let discountedFee = fee; // We keep the regular fee and calculate the discounted one
  let totalAmount;

  // Automatically apply discount for Edmonton and Calgary locations
  if (location === 'Edmonton' || location === 'Calgary') {
    discountedFee -= discountAmount; // Apply discount to the fee

    // Show promo code and discount in the table
    document.querySelector('#promoCodeRow').style.display = 'table-row';
    document.querySelector('#discountRow').style.display = 'table-row';
    document.querySelector('#td_Discount').innerHTML =
      '-$' + discountAmount.toFixed(2);
    document.querySelector('#td_PromoCode').innerHTML = promoCodeMap[location];
  } else {
    // Hide promo code and discount if not Edmonton or Calgary
    document.querySelector('#promoCodeRow').style.display = 'none';
    document.querySelector('#discountRow').style.display = 'none';
    document.querySelector('#td_Discount').innerHTML = '$0.00';
    document.querySelector('#td_PromoCode').innerHTML = '';
  }

  // Calculate tax if applicable
  if (feeType === 'IELTS EOR Fee') {
    // Always apply HST for EOR Fee regardless of the location
    isTaxable = true;
    document.querySelector('#td_TaxLabel').innerHTML = 'HST 13%';
    taxAmount = parseFloat(discountedFee * hstRate).toFixed(2);
    totalAmount = parseFloat(discountedFee * (1 + hstRate)).toFixed(2);
  } 
  else if(feeType === 'IELTS Express Shipping Fee') {
    // Always apply HST for IELTS Express Shipping Fee regardless of the location
    isTaxable = true;
    document.querySelector('#td_TaxLabel').innerHTML = 'HST 13%';
    taxAmount = parseFloat(discountedFee * hstRate).toFixed(2);
    totalAmount = parseFloat(discountedFee * (1 + hstRate)).toFixed(2);
  } 
  else if(feeType === 'Additional TRF Request Fee') {
    // Always apply HST for Additional TRF Request Fee regardless of the location
    isTaxable = true;
    document.querySelector('#td_TaxLabel').innerHTML = 'HST 13%';
    taxAmount = parseFloat(discountedFee * hstRate).toFixed(2);
    totalAmount = parseFloat(discountedFee * (1 + hstRate)).toFixed(2);
  } 
  else if(feeType === 'Additional TRF Request Plus Express Shipping Fee') {
    // Always apply HST for EOR Fee regardless of the location
    isTaxable = true;
    document.querySelector('#td_TaxLabel').innerHTML = 'HST 13%';
    taxAmount = parseFloat(discountedFee * hstRate).toFixed(2);
    totalAmount = parseFloat(discountedFee * (1 + hstRate)).toFixed(2);
  } 
  else if (isTaxable && locationTaxMap.hasOwnProperty(location)) {
    const taxType = locationTaxMap[location];
    let taxRate;
   
    if (taxType === 'HST') {
      taxRate = hstRate;
      document.querySelector('#td_TaxLabel').innerHTML = 'HST 13%'; // Show HST
    } else if (taxType === 'GST') {
      taxRate = gstRate;
      document.querySelector('#td_TaxLabel').innerHTML = 'GST 5%'; // Show GST
    }

    taxAmount = parseFloat(discountedFee * taxRate).toFixed(2);
    totalAmount = parseFloat(discountedFee * (1 + taxRate)).toFixed(2);
  } else {
    document.querySelector('#td_TaxLabel').innerHTML = 'GST/HST'; // Default label
    totalAmount = discountedFee;
  }

  // Update UI with the regular fee and discounted totals
  document.querySelector('#td_Fee').innerHTML = '$' + fee.toFixed(2); // Show the regular fee
  document.querySelector('#td_SubTotal').innerHTML =
    '$' + discountedFee.toFixed(2); // Show discounted subtotal
  document.querySelector('#td_Tax').innerHTML = '$' + taxAmount;
  document.querySelector('#td_Total').innerHTML = '$' + totalAmount;

  TaxAmount = taxAmount;
  TotalFee = totalAmount;
};

document.querySelector('#FeeType').addEventListener('change', onFeeTypeChange);

function onFeeTypeChange() {
  var FT = document.querySelector('#FeeType');
  if (FT != null) {
    HidePageControls();
    ClearLocationFields();
    clearPreviousFee(); // Clear previous fee before calculating new fee
    document.querySelector('#td_Tax').parentElement.style.display = '';
    var FeeType = FT.value;
    console.log('FeeType', FeeType);
    switch (FeeType) {
      case 'Course Fee':
        document.querySelector('#CourseFeeLocation').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML = 'Course Fee';
        CalculateTotal(courseFee, false);
        break;
      case 'Paper Based IELTS Test Fee':
        document.querySelector('#PBIeltsFeeLocation').style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML =
          'Paper Based IELTS Test Fee';
          // Reset the location dropdown explicitly
          document.querySelector('#pbIeltsLocationField').selectedIndex = 0;
        addLocationChangeListener('#pbIeltsLocationField', PBIelts);
        break;
      case 'Computer Delivered IELTS Test Fee':
        document.querySelector('#CDIeltsFeeLocation').style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML =
          'Computer Delivered IELTS Test Fee';
          // Reset the location dropdown explicitly
          document.querySelector('#cdIeltsLocationField').selectedIndex = 0;
        addLocationChangeListener('#cdIeltsLocationField', CDIelts);
        break;
      case 'IELTS EOR Fee':
        document.querySelector('#IeltsEORFeeLocation').style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML = 'IELTS EOR Fee';
        CalculateTotal(EORFee, true, 'HST','IELTS EOR Fee');
        break;
      case 'IELTS Express Shipping Fee':
        document.querySelector(
          '#IeltsExpressShippingFeeLocation'
        ).style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML =
          'IELTS Express Shipping Fee';
        CalculateTotal(ExpressShipping, true, 'HST', 'IELTS Express Shipping Fee');
        break;
      case 'Additional TRF Request Fee':
        document.querySelector(
          '#AdditionalTRFRequestFeeLocation'
        ).style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML =
          'Additional TRF Request Fee';
        CalculateTotal(AdditionalTRF, true, 'HST','Additional TRF Request Fee');
        break;
      case 'Additional TRF Request Plus Express Shipping Fee':
        document.querySelector(
          '#AdditionalTRFRequestPlusExpressShippingFeeLocation'
        ).style.display = 'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML =
          'Additional TRF Request Plus Express Shipping Fee';
        CalculateTotal(AdditionalTRFPlusExpressShipping, true, 'HST','Additional TRF Request Plus Express Shipping Fee');
        break;
      case 'IELTS Transfer Fee':
        document.querySelector('#IeltsTransferFeeLocation').style.display =
          'block';
        document.querySelector('#testDate').style.display = 'block';
        document.querySelector('#td_FeeType').innerHTML = 'IELTS Transfer Fee';
        CalculateTotal(IeltsTransfer, false);
        break;
      case 'Custom Fee':
        document.querySelector('#CustomFeeLocation').style.display = 'block';
        document.querySelector('#tr_CustomFee').style.display = 'table-row';
        document.querySelector('#td_FeeType').innerHTML = 'Custom Fee';

        // Event listener for custom fee input field
        document
          .querySelector('#customFeeField')
          .addEventListener('input', function () {
            const customFeeValue = parseFloat(this.value) || 0; // Get the custom fee value
            CalculateTotal(customFeeValue, false); // Call CalculateTotal for custom fee
          });
        document.querySelector('#td_Fee').innerHTML = '$0.00';
        break;
      case 'University Prepration Program Application Fee':
        document.querySelector('#UPPApplicationFeeLocation').style.display =
          'block';
        document.querySelector('#testDate').style.display = 'none';
        document.querySelector('#td_FeeType').innerHTML =
          'University Prepration Program Application Fee';
        CalculateTotal(UniversityPreprationProgramApplication, false);
        break;
      case 'English Literacy Development Program Application Fee':
        document.querySelector('#ELDPApplicationFeeLocation').style.display =
          'block';
        document.querySelector('#testDate').style.display = 'none';
        document.querySelector('#td_FeeType').innerHTML =
          'English Literacy Development Program Application Fee';
        CalculateTotal(EnglishLiteracyDevelopmentProgramApplication, false);
        break;
      default:
        break;
    }
  }
}

// Function to clear previous fee and tax information
function clearPreviousFee() {
  document.querySelector('#td_Fee').innerHTML = '$0.00';
  document.querySelector('#td_SubTotal').innerHTML = '$0.00';
  document.querySelector('#td_Tax').innerHTML = '$0.00';
  document.querySelector('#td_Total').innerHTML = '$0.00';

  // Reset the total fee and tax variables
  SubTotal = 0;
  TotalFee = 0;
  TaxAmount = 0;

  
}
function addLocationChangeListener(locationFieldId, baseFee) {
  const locationElement = document.querySelector(locationFieldId);
  locationElement.addEventListener('change', function () {
    const selectedLocation = this.value;

   // Check if location is Edmonton or Calgary
    if (selectedLocation === "Edmonton" || selectedLocation === "Calgary") {
      // Change base fee to 339 for Edmonton and Calgary
      const newFee = 359;
      //const isTaxable = locationTaxMap.hasOwnProperty(selectedLocation);
      const isTaxable = false;
      CalculateTotal(newFee, isTaxable, selectedLocation);
      // Hide the tax row by targeting the parent of the td element with id 'td_Tax'
      document.querySelector('#td_Tax').parentElement.style.display = 'none';
    } else {
      // Use the original base fee for other locations
      const isTaxable = locationTaxMap.hasOwnProperty(selectedLocation);
      CalculateTotal(baseFee, isTaxable, selectedLocation);
      // Show the tax row
      document.querySelector('#td_Tax').parentElement.style.display = '';
    }
  });
}

const ProcessPaymentLogic = async (token) => {
  base.renderSpinnerButton(document.querySelector('#paynowBtn'));

  var payInfo = {};
  payInfo.PaymentType = document.querySelector('#FeeType').value;
  payInfo.Location = getLocationByPaymentType(payInfo.PaymentType);
  payInfo.FirstName = document.querySelector('#firstNameField').value;
  payInfo.LastName = document.querySelector('#lastNameField').value;
  payInfo.Phone = document.querySelector('#phoneField').value;
  payInfo.Email = document.querySelector('#emailField').value;
  payInfo.Notes = document.querySelector('#noteField').value;
  payInfo.TestDate = document.querySelector('#testDateField').value;
  payInfo.Amount = TotalFee;
  payInfo.Tax = TaxAmount;
  payInfo.SubTotal = SubTotal;
  payInfo.CC_Name = document.querySelector('#cardholderNameField').value;
  payInfo.CC_Exp = document.querySelector('#expiryDateField').value;
  payInfo.CC_Number = document.querySelector('#cardNumberField').value;
  payInfo.CC_CVD = document.querySelector('#securityCodeField').value;
  payInfo.token = token;

  console.log('PayInfo', payInfo);
  await req
    .post('payment/processpayment', payInfo)
    .then(async function (response) {
      if (response.response != undefined) {
        if (response.response.status === 500) {
          console.log('Payment declined !!!!! ');
          console.log(response);
          console.log(response.response);
          base.notify_Error(
            'Something went wrong with the payment, please contact XYNA.',
            'Payment Declined!!!'
          );
        }
      } else {
        if (response.status === 200) {
          console.log('payment successfull');
          base.notify_Success('Payment Successful', 'Success!');
          await ClearPageControls();
          document.querySelector('#successMessageRow').style.display = 'block';
          document.querySelector('#successMessageRow_Message').innerHTML =
            "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

          document.querySelector('#paymentPageRow').style.display = 'none';
        }
      }
    })
    .catch(function (error) {
      console.log(error);
      base.notify_Error(
        'Something went wrong with the payment, please contact XYNA.',
        'Payment Error!!!'
      );
    });
  //base.notify_Success("Payment Successful", "Success!");
  base.clearSpinnerButton(document.querySelector('#paynowBtn'));
  //dispaly the message
};

const PreProcessPaymentLogic = async (token) => {
  base.renderSpinnerButton(document.querySelector('#paynowBtn'));

  var payInfo = {};
  payInfo.PaymentType = document.querySelector('#FeeType').value;
  payInfo.Location = getLocationByPaymentType(payInfo.PaymentType);
  payInfo.FirstName = document.querySelector('#firstNameField').value;
  payInfo.LastName = document.querySelector('#lastNameField').value;
  payInfo.Phone = document.querySelector('#phoneField').value;
  payInfo.Email = document.querySelector('#emailField').value;
  payInfo.Notes = document.querySelector('#noteField').value;
  payInfo.TestDate = document.querySelector('#testDateField').value;
  payInfo.Amount = TotalFee;
  payInfo.Tax = TaxAmount;
  payInfo.SubTotal = SubTotal;
  payInfo.CC_Name = document.querySelector('#cardholderNameField').value;
  payInfo.CC_Exp = document.querySelector('#expiryDateField').value;
  payInfo.CC_Number = document.querySelector('#cardNumberField').value;
  payInfo.CC_CVD = document.querySelector('#securityCodeField').value;
  payInfo.token = token;
  payInfo.OrderNumber = orderNumber;

  console.log('PayInfo', payInfo);
  await req
    .post('payment/preprocesspayment', payInfo)
    .then(async function (response) {
      if (response.response != undefined) {
        if (response.response.status === 500) {
          console.log('Payment declined !!!!! ');
          console.log(response);
          console.log(response.response);
          base.notify_Error(
            'Something went wrong with the payment, please contact XYNA.',
            'Payment Declined!!!'
          );
        }
      } else {
        if (response.status === 200) {
          console.log('pre process payment successfull');
          //base.notify_Success("Payment Successful", "Success!");
          //await ClearPageControls();
          //  document.querySelector('#successMessageRow').style.display = "block";
          // document.querySelector('#successMessageRow_Message').innerHTML = "Pre processing is done. data received ";

          const threeDSMethodURL = response.data[0];
          const threeDSMethodData = response.data[1];

          let displayBox = document.querySelector('#hiddendisplayBox');
          let iframe = document.createElement('iframe');
          iframe.classList.add('hidden');
          iframe.name = 'threeDSMethodIframe';
          iframe.id = '3dframe';
          displayBox.appendChild(iframe);

          iframe.addEventListener('load', async function (e) {
            // hide frame and show payment page

            //  setTimeout(async () => {
            // console.log("waiting 10 seconds before the cnext call");
            if (ThreeDFormSubmitted == false) {
              ThreeDFormSubmitted = true;
              console.log('Initiating call');

              grecaptcha.ready(function () {
                grecaptcha
                  .execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', {
                    action: 'submit',
                  })
                  .then(async function (token) {
                    payInfo.token = token;

                    await req
                      .post('payment/3DProcessPayment', payInfo)
                      .then(async function (response) {
                        if (response.response != undefined) {
                          if (response.response.status === 500) {
                            console.log('second step failure ');
                            console.log(response);
                            console.log(response.response);
                            base.notify_Error(
                              'Something went wrong with the payment, please contact XYNA.',
                              'Payment Declined!!!'
                            );
                          }
                        } else {
                          if (response.status === 200) {
                            console.log('3ds authentication request compelted');
                            console.log(response);
                            // base.notify_Success("Payment Successful", "Success!");
                            //await ClearPageControls();
                            //document.querySelector('#successMessageRow').style.display = "block";
                            // document.querySelector('#successMessageRow_Message').innerHTML = "3ds authentication request compelted";
                            if (
                              response.data != '' &&
                              response.data != null &&
                              response.data != 'undefined'
                            ) {
                              const challengedURL =
                                typeof response.data !== 'undefined'
                                  ? response.data[0]
                                  : '';
                              const challengeData =
                                typeof response.data !== 'undefined'
                                  ? response.data[1]
                                  : '';

                              if (challengedURL != '') {
                                document.querySelector(
                                  '#paymentPageRow'
                                ).style.display = 'none';
                                let displayBox =
                                  document.querySelector('#displayBox');
                                displayBox.style.display = 'flex';
                                let ciframe = document.createElement('iframe');
                                ciframe.id = 'skframe';
                                ciframe.classList.add('col-lg-12');
                                ciframe.classList.add('col-md-12');
                                ciframe.classList.add('col-sm-12');
                                ciframe.name = 'challengeIframe';
                                displayBox.appendChild(ciframe);

                                ciframe.addEventListener('load', function (e) {
                                  console.log(
                                    '🚀 ~ file: main.js ~ line 433 ~ FirstEventIncrement',
                                    FirstEventIncrement
                                  );

                                  console.log('ciframe load event ');
                                  if (FirstEventIncrement > 1) {
                                    // hide frame and show payment page
                                    displayBox.style.display = 'none';

                                    document.querySelector(
                                      '#paymentPageRow'
                                    ).style.display = 'flex';

                                    console.log(
                                      '******* Final testing********'
                                    );

                                    grecaptcha.ready(function () {
                                      grecaptcha
                                        .execute(
                                          '6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY',
                                          { action: 'submit' }
                                        )
                                        .then(async function (token) {
                                          // Add your logic to submit to  backend server.
                                          payInfo.token = token;
                                          await req
                                            .post(
                                              'payment/ThreeDSPaymentCAVVLookup',
                                              payInfo
                                            )
                                            .then(async function (response) {
                                              if (
                                                response.response != undefined
                                              ) {
                                                if (
                                                  response.response.status ===
                                                  500
                                                ) {
                                                  console.log(
                                                    'final step failure '
                                                  );
                                                  console.log(response);
                                                  console.log(
                                                    response.response
                                                  );
                                                  base.notify_Error(
                                                    'Something went wrong with the payment, please contact XYNA.',
                                                    'Payment Declined!!!'
                                                  );
                                                }
                                              } else if (
                                                response.status === 200
                                              ) {
                                                console.log(
                                                  'payment successfull'
                                                );
                                                base.notify_Success(
                                                  'Payment Successful',
                                                  'Success!'
                                                );
                                                await ClearPageControls();
                                                document.querySelector(
                                                  '#successMessageRow'
                                                ).style.display = 'block';
                                                document.querySelector(
                                                  '#successMessageRow_Message'
                                                ).innerHTML =
                                                  "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

                                                document.querySelector(
                                                  '#paymentPageRow'
                                                ).style.display = 'none';
                                              }
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                              base.notify_Error(
                                                'Something went wrong with the payment, please contact XYNA.',
                                                'Payment Error!!!'
                                              );
                                            });
                                        });
                                    });
                                  }
                                  FirstEventIncrement++;
                                  console.log(e.target);

                                  //console.log(document.querySelector('#skframe'));
                                });

                                let formChallenge =
                                  document.querySelector('#challengeForm');

                                document.querySelector('#creq').value =
                                  challengeData;
                                console.log('sending data to the URL');
                                formChallenge.action = challengedURL;
                                formChallenge.target = 'challengeIframe'; // id of iframe
                                formChallenge.method = 'post';
                                formChallenge.submit();
                                console.log('form data submitted');
                              }
                            } else {
                              console.log('NO challenge required.');
                              console.log('payment successfull');
                              base.notify_Success(
                                'Payment Successful',
                                'Success!'
                              );
                              await ClearPageControls();
                              document.querySelector(
                                '#successMessageRow'
                              ).style.display = 'block';
                              document.querySelector(
                                '#successMessageRow_Message'
                              ).innerHTML =
                                "Your payment was successful! You will recieve a receipt in your email (Please check spam / junk folder if you don't find receipt in your inbox). ";

                              document.querySelector(
                                '#paymentPageRow'
                              ).style.display = 'none';
                            }

                            //document.querySelector('#paymentPageRow').style.display = "none";
                          }
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                        base.notify_Error(
                          'Something went wrong with the payment, please contact XYNA.',
                          'Payment Error!!!'
                        );
                      });
                  });
              });

              // console.log(e.target);
            }
          });

          let form = document.querySelector('#threeDSMethodForm');
          document.querySelector('#threeDSMethodData').value =
            threeDSMethodData;
          console.log('sending data to the URL');
          form.action = threeDSMethodURL;
          form.target = 'threeDSMethodIframe'; // id of iframe
          form.method = 'post';
          form.submit();

          console.log('form data submitted, Now proceeding for the next step');

          //  document.querySelector('#paymentPageRow').style.display = "none";

          //}, 10000);
        }
      }
    })
    .catch(function (error) {
      console.log(error);
      base.notify_Error(
        'Something went wrong with the payment, please contact XYNA.',
        'Payment Error!!!'
      );
    })
    //base.notify_Success("Payment Successful", "Success!");
    //base.clearSpinnerButton(document.querySelector('#paynowBtn'));
    //dispaly the message
    .catch(function (error) {
      console.log(error);
      base.notify_Error(
        'Something went wrong with the pre-processing',
        'Pre-Processing Error!'
      );
    });

  base.clearSpinnerButton(document.querySelector('#paynowBtn'));
};

const ValidateForm = () => {
  if (
    document.querySelector('#FeeType').value == null ||
    document.querySelector('#FeeType').value == ''
  ) {
    console.log('Fee Type is required!');
    base.notify_Error('Fee Type Missing', 'Fee Type is required!');
    return false;
  }
  if (
    document.querySelector('#firstNameField').value == '' ||
    document.querySelector('#lastNameField').value == '' ||
    document.querySelector('#phoneField').value == '' ||
    document.querySelector('#emailField').value == ''
  ) {
    console.log('Required fields are missing!');
    base.notify_Error('Required Fields Missing', 'All fields are required!');
    return false;
  }
  if (document.querySelector('#cardNumberField').value.length !== 16) {
    console.log('Card Number must be 16 digits');
    base.notify_Error('Card Number Invalid', 'Card Number must be 16 digits!');
    return false;
  }
  return true;
};

function getLocationByPaymentType(paymentType) {
  switch (paymentType) {
    case 'Course Fee':
      return document.querySelector('#courseLocationField').value;
    case 'Paper Based IELTS Test Fee':
      return document.querySelector('#pbIeltsLocationField').value;
    case 'Computer Delivered IELTS Test Fee':
      return document.querySelector('#cdIeltsLocationField').value;
    case 'IELTS EOR Fee':
      return document.querySelector('#ieltsEORLocationField').value;
    case 'IELTS Express Shipping Fee':
      return document.querySelector('#expressShippingLocationField').value;
    case 'Additional TRF Request Fee':
      return document.querySelector('#trfRequestLocationField').value;
    case 'Additional TRF Request Plus Express Shipping Fee':
      return document.querySelector(
        '#trfRequestPlusExpressShippingLocationField'
      ).value;
    case 'IELTS Transfer Fee':
      return document.querySelector('#ieltsTransferLocationField').value;
    case 'Custom Fee':
      return document.querySelector('#customLocationField').value;
    default:
      return '';
  }
}
document
  .querySelector('#paynowBtn')
  .addEventListener('click', async function (e) {
    e.preventDefault();
    console.log('payment initialize');
    //validate fields
    if (ValidateForm()) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute('6LeHbFkaAAAAAMBWIOhBNaXNy9roQGwThYe33UzY', {
            action: 'submit',
          })
          .then(async function (token) {
            // Add your logic to submit to  backend server.

            if (
              document.querySelector('#FeeType').value != null &&
              document.querySelector('#FeeType').value != ''
            ) {
              if (document.querySelector('#FeeType').value == 'Custom Fee') {
                await PreProcessPaymentLogic(token);
              } else {
                await ProcessPaymentLogic(token);
              }
            }

            //await ProcessPaymentLogic(token);
            //  await PreProcessPaymentLogic(token);
          });
      });
    }
  });
  document.getElementById('expiryDateField').addEventListener('input', function(e) {
    // Remove any non-numeric characters
    this.value = this.value.replace(/[^0-9]/g, '');
    
    // Limit to a maximum of 4 characters
    if (this.value.length > 4) {
        this.value = this.value.slice(0, 4);
    }

    // Hide error if input is being edited to correct value
    document.getElementById('expiryDateError').style.display = 'none';
    this.classList.remove('is-invalid');
});

document.getElementById('expiryDateField').addEventListener('blur', function() {
    // Validation check for MMYY format
    if (this.value.length !== 4) {
        document.getElementById('expiryDateError').style.display = 'block';
        this.classList.add('is-invalid');
    } else {
        document.getElementById('expiryDateError').style.display = 'none';
        this.classList.remove('is-invalid');
    }
});
// Initialize the controls when the page loads
Initialize();
